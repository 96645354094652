/* unplugin-vue-components disabled */import __unplugin_components_0 from '/var/www/front/src/components/common/ui/Form/Index.vue';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(__unplugin_components_0,{staticClass:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[(
            _vm.promocodeShow && (_vm.action === 'login' || _vm.action === 'register')
        )?[_c('div',{staticClass:"promocode-notice-row"},[_c('BillingPromocodeNotice',{staticClass:"promocode-notice",attrs:{"parent":"Register"}})],1)]:_vm._e(),(_vm.action === 'restorepassword' && _vm.restorepasswordComplete)?[_c('div',{staticClass:"alert"},[_vm._v("包含新密码的电子邮件已发送至")]),_c('div',{staticClass:"msg"},[_vm._v("如果信件在 15 分钟内未送达, 请检查\"垃圾邮件\"部分或写信给支持人员 - support@salesfinder.cn")])]:[_vm._l((_vm.active_inputs),function(item,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
                ( _vm.active_inputs.find(function (item) { return item.name === 'user_phone'; })
                  && _vm.active_inputs
                        .find(function (item) { return item.name === 'user_phone'; })
                        .show_success
                )
                || item.name !== 'user_code'
            ),expression:"\n                ( active_inputs.find((item) => item.name === 'user_phone')\n                  && active_inputs\n                        .find((item) => item.name === 'user_phone')\n                        .show_success\n                )\n                || item.name !== 'user_code'\n            "}],key:i,staticClass:"input-block"},[(item.name !== 'user_code')?_c('div',{staticClass:"input-block-title"},[_c('div',{staticClass:"input-title"},[_vm._v(_vm._s(item.title))]),[_c('fade-transition',[(
                                item.show_error &&
                                item.touched &&
                                item.error_text.length < 101
                            )?_c('div',{staticClass:"input-error",domProps:{"innerHTML":_vm._s(item.error_text)}}):_vm._e()]),(item.show_success && item.touched)?_c('img',{staticClass:"input-img",attrs:{"src":require("@/assets/images/icons/success.svg")}}):_vm._e(),(item.show_error && item.touched)?_c('img',{staticClass:"input-img img-error",attrs:{"src":require("@/assets/images/icons/close.svg")}}):_vm._e()]],2):_vm._e(),(item.type === 'tel')?_c('div',[_c('VuePhoneNumberInput',{attrs:{"size":"md","no-example":"","required":"","error":item.show_error,"valid-color":"#3E8A5D","error-color":"#bb2229","translations":{ countrySelectorLabel: '国家代码', countrySelectorError: '国家选择错误', phoneNumberLabel: '电话号码', example: '示例：', }},on:{"update":_vm.updatePhone,"phone-number-focused":function($event){item.active = true},"phone-number-blur":function($event){return _vm.onBlur(item)}},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})],1):(item.name === 'user_code')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.active_inputs
                        .find(function (item) { return item.name === 'user_phone'; })
                        .show_success
                ),expression:"\n                    active_inputs\n                        .find((item) => item.name === 'user_phone')\n                        .show_success\n                "}]}):_c('div',{staticClass:"input",class:{ active: item.active }},[((item.type === 'password' && item.show_password
                            ? 'text'
                            : item.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.model),expression:"item.model"}],attrs:{"placeholder":item.placeholder,"autofocus":!i,"name":item.show_name !== false && item.name,"type":"checkbox"},domProps:{"checked":Array.isArray(item.model)?_vm._i(item.model,null)>-1:(item.model)},on:{"focus":function($event){item.active = true},"blur":function($event){return _vm.onBlur(item)},"change":function($event){var $$a=item.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "model", $$c)}}}}):((item.type === 'password' && item.show_password
                            ? 'text'
                            : item.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.model),expression:"item.model"}],attrs:{"placeholder":item.placeholder,"autofocus":!i,"name":item.show_name !== false && item.name,"type":"radio"},domProps:{"checked":_vm._q(item.model,null)},on:{"focus":function($event){item.active = true},"blur":function($event){return _vm.onBlur(item)},"change":function($event){return _vm.$set(item, "model", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.model),expression:"item.model"}],attrs:{"placeholder":item.placeholder,"autofocus":!i,"name":item.show_name !== false && item.name,"type":item.type === 'password' && item.show_password
                            ? 'text'
                            : item.type},domProps:{"value":(item.model)},on:{"focus":function($event){item.active = true},"blur":function($event){return _vm.onBlur(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "model", $event.target.value)}}}),(item.type == 'password')?_c('svg',{staticClass:"icon-eye",attrs:{"width":"16","height":"12","viewBox":"0 0 16 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":function($event){item.show_password = !item.show_password}}},[_c('path',{attrs:{"d":"M5.87143 6C5.87143 6.5467 6.08796 7.07102 6.47338 7.45759C6.85879 7.84417 7.38153 8.06135 7.9266 8.06135C8.47166 8.06135 8.9944 7.84417 9.37982 7.45759C9.76524 7.07102 9.98176 6.5467 9.98176 6C9.98176 5.4533 9.76524 4.92898 9.37982 4.54241C8.9944 4.15583 8.47166 3.93865 7.9266 3.93865C7.38153 3.93865 6.85879 4.15583 6.47338 4.54241C6.08796 4.92898 5.87143 5.4533 5.87143 6ZM15.894 5.52515C14.1545 1.84969 11.525 0 8 0C4.47319 0 1.84551 1.84969 0.105961 5.52699C0.0361868 5.67518 0 5.83704 0 6.00092C0 6.1648 0.0361868 6.32666 0.105961 6.47485C1.84551 10.1503 4.47502 12 8 12C11.5268 12 14.1545 10.1503 15.894 6.47301C16.0353 6.17485 16.0353 5.82883 15.894 5.52515ZM7.9266 9.23926C6.14301 9.23926 4.69705 7.78896 4.69705 6C4.69705 4.21104 6.14301 2.76074 7.9266 2.76074C9.71019 2.76074 11.1561 4.21104 11.1561 6C11.1561 7.78896 9.71019 9.23926 7.9266 9.23926Z","fill":"#C4C4C4"}})]):_vm._e()]),(
                    item.show_error &&
                    item.touched &&
                    item.error_text.length > 100
                )?_c('div',{staticStyle:{"color":"#bb2229"},domProps:{"innerHTML":_vm._s(item.error_text)}}):_vm._e()])}),_c('focus',{key:_vm.action}),_c('button',{staticClass:"button",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.titleButton)+" ")])],(_vm.action == 'register' || _vm.action == 'restorepassword')?_c('div',{staticClass:"notice"},[_c('span',[_vm._v("已经有帐户?")]),_c('router-link',{attrs:{"to":{ name: 'Login' },"active-class":""}},[_vm._v("登录")])],1):_vm._e(),(_vm.action == 'login')?_c('div',{staticClass:"notice"},[_c('span',[_vm._v("忘记密码了吗?")]),_c('router-link',{attrs:{"to":{ name: 'RestorePassword' },"active-class":""}},[_vm._v("恢复密码")])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }